import React from 'react';
import { FilePond } from 'react-filepond';
// import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';
// import {Button} from 'react-bootstrap';

// Register the plugin
// registerPlugin(FilePondPluginFileValidateType);
class UploadButtons extends React.Component {
  // const classes = useStyles();
  constructor(props) {
    super(props);

    this.state = {
      // Set initial files, type 'local' means this is a file
      // that has already been uploaded to the server (see docs)
      files: null
    };
  }
  handleInit() {
    console.log("FilePond instance has initialised", this.pond);
  }
  handleProcess = () => {
    console.log("FilePond instance has handleProcess", this.pond);
    console.log(this.state.files);
  }

  render() {

    let ip = "refer.gd4.co.th";
    ip = window.location.hostname;
    const url = `http://${ip}:3500/refer/upload/` + this.props.guid;
    // const url = `http://refer.gd4.co.th:3500/refer/upload/` + this.props.guid;
    // const revert= `./refer/delete/` + this.props.guid;
    console.log(url);
    return (
      <div className="App">
        <header className="App-header">
          {/* <img src={logo} className="App-logo" alt="logo" /> */}
          <h1 className="App-title">UNEOX Refer</h1>
        </header>
        <p className="App-intro">
          กรุณาเลือกไฟล์ (.dcm)
  </p>
        <FilePond
          //  files={this.state.files}
          ref={ref => (this.pond = ref)}
          oninit={() => this.handleInit()}
          onprocessfile={(error, file) => this.handleProcess()}
          allowMultiple={true}
          allowRevert={false}
          // instantUpload={false}
          // acceptedFileTypes={['.dcm']}
          // fileValidateTypeLabelExpectedTypesMap={{ 'application/dcm': '.dcm' }}

          server={url}
          labelButtonProcessItem="อัพโหลด"
          onupdatefiles={fileItems => {
            // Set currently active file objects to this.state
            this.setState({
              files: fileItems.map(fileItem => fileItem.file.name)
            });

            this.props.handleFiles(this.state.files);
          }}
          // revert={revert}
          labelIdle='ลากและวางไฟล์ หรือ <span class="filepond--label-action"> Browse.. </span>'
        />
      </div>
      //   <div className={classes.root}>
      //     <input
      //       accept="image/*"
      //       className={classes.input}
      //       id="contained-button-file"
      //       multiple
      //       type="file"
      //     />
      //     <label htmlFor="contained-button-file">
      // <Button variant="contained" color="primary" startIcon={<CloudUploadIcon />} component="span">
      //         Upload
      //       </Button>
      //     </label>
      //   </div>
    );
  }
}

export default UploadButtons;