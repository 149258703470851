import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
// import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core";

import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader';
// import CardActionArea from "@material-ui/core/CardActionArea";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import axios from 'axios';
import Link from '@material-ui/core/Link';

import Snackbar from '@material-ui/core/Snackbar';
// import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import MuiAlert from '@material-ui/lab/Alert';

import * as member from '../Utils/Common';

const url = 'http://' + window.location.hostname + '/api';


// import { Link } from 'react-router-dom';
function Alert(props) {
  return <MuiAlert elevation={1} variant="filled" {...props} />;
}


const useStyles = theme => ({
  root: {
    width: "100%",
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
  },
  media: {
    height: 260,
    // paddingTop: "56.25%" // 16:9
  },
  toolbar: theme.mixins.toolbar,
  input: {
    width: "220px",
    fontSize: '17px',
    color: '#003366',
    fontWeight: 'bold',
  }
});

class login extends React.Component {
  state = {
    username: '',
    password: '',
    showPassword: false,
    alert: false,
    alertMsg: ''
  };

  render() {
    member.getSession('Study');

    const { classes } = this.props;

    const handleClose = () => {
      this.setState({ ...this.state, alert: false });
    }

    const msg = (info, st) => {
      this.setState({ ...this.state, alert: st, alertMsg: info });
    }

    // const [values, setValues] = React.useState({
    //   username: '',
    //   password: '',
    //   showPassword: false,
    // });
    const handleChange = prop => event => {
      this.setState({ ...this.state, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
      this.setState({ ...this.state, showPassword: !this.state.showPassword });
    };

    const handleMouseDownPassword = event => {
      event.preventDefault();
    };

    const onLogin = () => {
      if (this.state.username.length > 0 &&
        this.state.password.length > 0) {
        // console.log('login ' + window.location.hostname + ':3500/login/');
        axios.get(url + '/login/' + this.state.username + "/" + this.state.password)
          .then(dt => {
            // console.log(dt.data);
            let fi = dt.data;
            if (fi[0]) {
              // OK       

              if (fi[0].userType === 'USR') {
                let usr = fi[0];
                axios.get(url + '/hospital/' + usr.hosId)
                  .then(h => {
                    let hos = h.data;
                    // console.log(hos[0]);
                    if (hos[0]) {
                      usr["hosName"] = hos[0].name;
                    } else {
                      usr["hosName"] = "N/A";
                    }
                    member.setMember(usr);
                    window.location.href = '/Study';
                  })
                // localStorage.setItem('user', JSON.stringify(fi[0]));
                // console.log(usr);
                msg('กรุณารอสักครู่', true);
              }
              else
                msg('คุณไม่มีสิทธิในการใช้งาน กรุณาติดต่อเจ้าหน้าที่ของบริษัท', true);
            } else {
              msg('ข้อมูลไม่ถูกต้อง กรุณาลองอีกครั้ง', true);
            }
          })
      } else {
        this.setState({ username: '', password: '' });
        msg('กรุณากรอกข้อมูลให้ครบ', true);
      }
    }
    const onCancel = () => {
      this.setState({ username: '', password: '' });
    }

    return (
      <div>
        <React.Fragment>
          <CssBaseline />
          <Container fixed maxWidth="md">
            <Typography component="div" style={{ height: '100vh' }} >
              <div className={classes.toolbar} />
              <Card className={classes.root} style={{ backgroundColor: "#fff", borderRadius: 20 }}>
                <CardHeader
                  avatar={
                    <img
                      src={require("../../components/images/gd4.png")}
                      width="40"
                      height="30"
                      className="d-inline-block align-top"
                      alt="UNEOX"
                    />
                  }
                  title={<Typography variant="h6" className={classes.title} noWrap>
                    <span style={{ color: "#0000bb" }}>UNEO</span><span style={{ color: "#ff0000" }}>X</span>
                  </Typography>}
                  subheader="Radiology Referral"
                />
                <CardMedia
                  className={classes.media}
                  image={require('../images/head.png')}
                  title="UNEO Refer"
                />
                <CardContent style={{ backgroundColor: "#a8bdd5" }}>
                  <form
                    onKeyPress={event => {
                      if (event.which === 13 /* Enter */) {
                        event.preventDefault();
                      }
                    }}
                  >
                    <Grid container className={classes.root} spacing={2}>
                      <Grid item xs={12}>
                        <Grid container justify="center" spacing={1}>
                          <form className={classes.form} noValidate autoComplete="off">
                            <h5 style={{ color: '#272b46' }}>Login</h5>
                            <Paper component="form" className={classes.input} >
                              <InputBase
                                className={classes.input}
                                placeholder="รหัสผู้ใช้"
                                value={this.state.username}
                                onChange={handleChange('username')}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <AccountCircle color="primary" />
                                  </InputAdornment>
                                }
                              />
                            </Paper>
                            <br></br>
                            <Paper component="form" className={classes.input} >
                              <InputBase
                                className={classes.input}
                                placeholder="รหัสผ่าน"
                                type={this.state.showPassword ? 'text' : 'password'}
                                value={this.state.password}
                                onChange={handleChange('password')}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {this.state.showPassword ? <Visibility color="secondary" /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </Paper>
                            <br></br>
                            <Button variant="contained" color="primary" onClick={onLogin} >
                              เข้าสู่ระบบ
                      </Button>
                            {"\u00A0"}
                            {"\u00A0"}
                            {"\u00A0"}
                            <Button variant="contained" onClick={onCancel} >
                              ยกเลิก
                      </Button>
                            <br />   <br />
                            <Typography className={classes.root}>
                              <Link href="/Register">
                                ลงทะเบียน
                            </Link>
                            </Typography>
                          </form>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
              <p style={{ color: "#fff" }}>version 1.0 last update 03/2020</p>
            </Typography>
          </Container>
        </React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={this.state.alert}
          autoHideDuration={3000}
          onClose={handleClose}                >
          <Alert onClose={handleClose} severity="error">
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default withStyles(useStyles)(login);
