import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import 'react-day-picker/lib/style.css';
import 'moment/locale/th';
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import CakeIcon from '@material-ui/icons/Cake';
import SendIcon from '@material-ui/icons/Send';
// import WcIcon from '@material-ui/icons/Wc';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

class Inputs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hn: "",
      cid: "",
      fname: "",
      lname: "",
      bdate: "",
      refid: "",
      guid: "",
      sex: "",
      stuDate: "",
      stuTime: "",
      doctor: "",
      stuDesc: "",

      ...this.props.patient
    };
    console.log(this.props.patient);
    this.handleChange = this.handleChange.bind(this);
    moment.locale('th');
  }
  handleChange = (e) => {
    const value = e.target.value;
    console.log(this.state + " " + value);
    this.setState({
      ...this.state,
      [e.target.name]: value
    }, () => {
      this.props.handlePatient(this.state);
      //  console.log('Handle' + e.target.name);
      console.log(this.state);
    })
  }
  handleDateChange = date => {
    // setSelectedDate(date);
    this.setState({
      ...this.state,
      bdate: moment(date).format("YYYYMMDD")
    }, () => {
      this.props.handlePatient(this.state);
      //  console.log('Handle' + e.target.name);
      console.log(this.state);
    })
  }

  render() {

    const { classes } = this.props;



    return (
      <form className={classes.root} noValidate autoComplete="on">
        <TextField
          id="hn"
          name="hn"
          label="HN"
          color="primary"
          value={this.state.hn}
          onChange={this.handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <RecentActorsIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id="cid"
          name="cid"
          label="เลขบัตรประชาชน"
          color="primary"
          value={this.state.cid}
          onChange={this.handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PermIdentityIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-simple-select-label">เพศ</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="sex"
            name="sex"
            value={this.state.sex}
            onChange={this.handleChange}
          >
            <MenuItem value={'M'}>ชาย</MenuItem>
            <MenuItem value={'F'}>หญิง</MenuItem>
            <MenuItem value={'O'}>ไม่ระบุ</MenuItem>
          </Select>
        </FormControl>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          id="fname"
          name="fname"
          label="ชื่อ"
          color="primary"
          value={this.state.fname}
          onChange={this.handleChange}
        />
        <TextField
          id="lname"
          name="lname"
          label="นามสกุล"
          color="primary"
          value={this.state.lname}
          onChange={this.handleChange}
        />

        {/* <TextField
        id="bdate"
        name="bdate"
        label="วันเดือนปีเกิด dd/MM/yyyy"
        variant="filled"
        color="primary"
        value={this.state.bdate}
        onChange={this.handleChange}
      /> */}
        <MuiPickersUtilsProvider utils={MomentUtils} locale='th'>
          <KeyboardDatePicker
            margin="normal"
            id="bdate"
            label="วัน/เดือน/ปี เกิด"
            format="DD/MM/YYYY"
            value={this.state.bdate}
            onChange={this.handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CakeIcon />
                </InputAdornment>
              ),
            }}
          />
        </MuiPickersUtilsProvider>
        <TextField
          id="refid"
          name="refid"
          label="Refer ID."
          color="primary"
          value={this.state.refid}
          onChange={this.handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SendIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id="guid"
          name="guid"
          label="GUID"
          disabled
          fullWidth
          value={this.state.guid}
          onChange={this.handleChange}
        />
      </form>
    );
  }
}
export default withStyles(useStyles)(Inputs);