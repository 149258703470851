let _member = JSON.parse(localStorage.getItem('user'));

export function usr() {
  return _member;
};

export function fullname() {
  return _member.fullname;
};

export function hosId() {
  if (_member)
    return _member.hosId;
};

export function hosName() {
  if (_member)
    return _member.hosName;
};

export function getSession(url) {
  if (_member === null) {
    return false;
  } else {
    if (url)
      window.location.href = '/' + url;
    return true;
  }
}

export function setMember(usr) {
  localStorage.setItem('user', JSON.stringify(usr));
  _member = JSON.parse(localStorage.getItem('user'));
}

export function onSession() {
  console.log('session');
  if (!_member) {
    window.location.href = '/Login';
  }
}

export function getRight(rule, url) {
  console.log('session ' + rule);
  console.log(_member[rule]);
  if (!_member) {
    window.location.href = '/Login';
  }

  if (_member[rule] !== true) {
    window.location.href = url;
  }
}