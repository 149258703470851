// import React, { Component } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import JSZipUtils from 'jszip-utils/dist/jszip-utils';
import axios from 'axios';
import * as member from '../Utils/Common';

const url = 'http://' + window.location.hostname + '/api';

function filePath(guid) {
    let hos = guid.substring(0, guid.indexOf('.'));
    let dt = guid.substring(guid.indexOf('.') + 1, guid.indexOf('.') + 9);
    return `${window.location.origin}/IMG/STUDY/${dt}/${hos}/${guid}/`;
}
// import { parseWithOptions } from 'date-fns/fp';
// import { zip } from 'react-native-zip-archive';
export const downloadDCM = (data, filename) => {
    let f = filePath(data.guid) + filename;
    return saveAs(f, filename);
}



export function downloadAll(guid, files) {
    let zip = new JSZip(); 

    // const file = [];
    // console.log(url + '/study/downloadlog/' + guid + '/' + member.fullname());
    axios.post(url + '/study/downloadlog/' + guid + '/' + member.fullname() + "[" + member.hosName() + "]")
        .then(dt => {
            console.log(dt);
        })
        .catch(function (error) {
            // handle error
            console.log(error);
        })

    let path = filePath(guid);
    const filename = guid + ".zip";
    let count = 0;

    console.log(files);
                        // ADD DCMTK 
      
                        const fi1=require('../../components/asset/bin/dcmdata.dll');
                        JSZipUtils.getBinaryContent(fi1, function (err, data) {
                            if (err) {
                                // throw err; // or handle the error
                                console.log(err);
                            }
                            else {
                                zip.folder("bin").file('dcmdata.dll', data, { binary: true });
                            }
                        });
                        const fi2=require('../../components/asset/bin/dcmnet.dll');
                        JSZipUtils.getBinaryContent(fi2, function (err, data) {
                            if (err) {
                                // throw err; // or handle the error
                                console.log(err);
                            }
                            else {
                                zip.folder("bin").file('dcmnet.dll', data, { binary: true });
                            }
                        });
                        const fi3=require('../../components/asset/bin/dcmodify.exe');
                        JSZipUtils.getBinaryContent(fi3, function (err, data) {
                            if (err) {
                                // throw err; // or handle the error
                                console.log(err);
                            }
                            else {
                                zip.folder("bin").file('dcmodify.exe', data, { binary: true });
                            }
                        });
                        const fi4=require('../../components/asset/bin/dcmtls.dll');
                        JSZipUtils.getBinaryContent(fi4, function (err, data) {
                            if (err) {
                                // throw err; // or handle the error
                                console.log(err);
                            }
                            else {
                                zip.folder("bin").file('dcmtls.dll', data, { binary: true });
                            }
                        });
                        const fi5=require('../../components/asset/bin/oflog.dll');
                        JSZipUtils.getBinaryContent(fi5, function (err, data) {
                            if (err) {
                                // throw err; // or handle the error
                                console.log(err);
                            }
                            else {
                                zip.folder("bin").file('oflog.dll', data, { binary: true });
                            }
                        });
                        const fi6=require('../../components/asset/bin/ofstd.dll');
                        JSZipUtils.getBinaryContent(fi6, function (err, data) {
                            if (err) {
                                // throw err; // or handle the error
                                console.log(err);
                            }
                            else {
                                zip.folder("bin").file('ofstd.dll', data, { binary: true });
                            }
                        });
                        const fi7=require('../../components/asset/bin/storescu.exe');
                        JSZipUtils.getBinaryContent(fi7, function (err, data) {
                            if (err) {
                                // throw err; // or handle the error
                                console.log(err);
                            }
                            else {
                                zip.folder("bin").file('storescu.exe', data, { binary: true });
                            }
                        });
                        const fi8=require('../../components/asset/RUN.txt');
                        JSZipUtils.getBinaryContent(fi8, function (err, data) {
                            if (err) {
                                // throw err; // or handle the error
                                console.log(err);
                            }
                            else {
                                zip.file('RUN.bat', data, { binary: true });
                            }
                        });                     

    files.map((d) => {
        let f = path + d;
        console.log(f);


        JSZipUtils.getBinaryContent(f, function (err, data) {
            if (err) {
                console.log(err);
                throw err;
            }
            zip.file(d, data, { binary: true });
            count++;
            console.log(count + " " + files.length);
            if (count === files.length) {

                zip.generateAsync({ type: 'blob' }).then(function (content) {
                    saveAs(content, filename);
                });
            }
        });

        return f;
    });
}