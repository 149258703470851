import React from "react";
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from "@material-ui/core/IconButton";
import axios from 'axios';
import Moment from 'moment';
import Badge from '@material-ui/core/Badge';
import * as member from '../Utils/Common';
const url = 'http://' + window.location.hostname + '/api';
class Notify extends React.Component {
  intervalID;
  state = {
    StudyCase: 0,
    oldCase: 0
  }
  constructor(props) {
    super(props);

    this.state = {
      // Set initial files, type 'local' means this is a file
      // that has already been uploaded to the server (see docs)
      files: null
    };

  }
  componentDidMount() {
    // need to make the initial call to getData() to populate
    // data right away
    this.getData();
    console.log('interval');

    // Now we need to make it run at a specified interval
    this.intervalID = setInterval(this.getData.bind(this), 20000);
  }

  componentWillUnmount() {
    /*
      stop getData() from continuing to run even
      after unmounting this component
    */
    clearInterval(this.intervalID);
  }
  getData = () => {

    const to = Moment().format("YYYYMMDD");
    //console.log("Moment : " + from + "-" + to);
    // console.log("ACTION : " + from + " " + to);

    axios
      .get(url + "/study/date/" + member.hosId() + "/" + to + "/" + to)
      .then(res => {
        const d = res.data;
        // console.log(d);
        let i = 0;
        d.forEach((s) => {
          if (s.Accept !== true & s.toHos === member.hosId()) {
            //console.log(s);
            i++;
          }
        })
        this.setState({ StudyCase: i });
        this.props.handleNewStudy(this.state.StudyCase, this.state.oldCase);
        this.setState({ oldCase: i });
        //console.log(res.data);
        // dispatch({
        //  type: "GET_DATA",
        // playload: res.data
        // });
      })
      .catch(errors => {
        this.setState({ StudyCase: 0 });
        // react on errors.
        console.error("ERR:getStudyByDate " + errors);
      });

    console.log(1);
    // do something to fetch data from a remote API.
  }
  render() {
    const handleOnClick = () => {
      window.location.reload();
    }
    //console.log('ttt');
    return (
      <IconButton onClick={handleOnClick}>
        <Badge color="secondary" badgeContent={this.state.StudyCase}>
          <NotificationsIcon style={{ color: "#D64F15" }} />
        </Badge>
      </IconButton>
    )
  }
}
export default Notify;