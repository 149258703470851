import React from "react";
import { Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
// import { LocalHospital, Search } from "@material-ui/icons/";

import DayPicker, { DateUtils } from "react-day-picker";

import "react-day-picker/lib/style.css";
import "moment/locale/th";
import MomentLocaleUtils from "react-day-picker/moment";
// import Box from '@material-ui/core/Box';

import { connect } from "react-redux";

import * as action from "./action";

import Moment from "moment";

import Button from "@material-ui/core/Button";

// import { DateRange } from '@material-ui/icons/';

import Paper from "@material-ui/core/Paper";

import Helmet from "react-helmet";

import Divider from "@material-ui/core/Divider";

class leftside extends React.Component {
  componentDidMount() {
    this.props.dispatchFromStore();
  }

  static defaultProps = {
    numberOfMonths: 1
  };

  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.state = this.getInitialState();
  }
  getInitialState() {
    return {
      from: null,
      to: null,
      enteredTo: null // Keep track of the last day for mouseEnter.
    };
  }
  isSelectingFirstDay(from, to, day) {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }

  handleDayClick(day) {
    const { from, to } = this.state;
    if (from && to && day >= from && day <= to) {
      this.handleResetClick();
      return;
    }
    if (this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        from: day,
        to: null,
        enteredTo: null
      });
    } else {
      this.setState({
        to: day,
        enteredTo: day
      });
    }
  }

  handleResetClick() {
    this.setState(this.getInitialState());
  }

  handleDayMouseEnter(day) {
    const { from, to } = this.state;
    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day
      });
    }
  }

  render() {
    const { from, to, enteredTo } = this.state;
    const modifiers = { start: from, end: enteredTo };
    const disabledDays = { before: this.state.from };
    const selectedDays = [from, { from, to: enteredTo }];

    // Event เลือกวันที่แสดง
    if (from && to) {
      this.props.getStudyFromStore(this.state.from, this.state.to);
    }
    return (
      <div >
        <Form>
          <Paper className="text-center">
            {!from && !to && "กรุณาเลือก วันที่"}
            {from && !to && "กรุณาเลือก วันที่สิ้นสุด"}
            {from &&
              to &&
              `${Moment(from.toLocaleDateString('us-US')).format(
                "DD/MM/YYYY"
              )} -
                ${Moment(to.toLocaleDateString('us-US')).format("DD/MM/YYYY")}`}{" "}
            {from && to && (
              <Button
                variant="contained"
                color="secondary"
                onClick={this.handleResetClick}
              >
                ล้าง
              </Button>
            )}
            <Divider />
            <DayPicker
              className="Range"
              numberOfMonths={this.props.numberOfMonths}
              selectedDays={selectedDays}
              disabledDays={disabledDays}
              modifiers={modifiers}
              onDayClick={this.handleDayClick}
              onDayMouseEnter={this.handleDayMouseEnter}
              localeUtils={MomentLocaleUtils}
              locale="th"
              todayButton="แสดงวันนี้"
              onTodayButtonClick={(day, modifiers) =>
                this.setState({
                  from: day,
                  to: day,
                  enteredTo: null
                })
              }
            />
          </Paper>
        </Form>
        <Helmet>
          <style>{`
  .Range .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .Range .DayPicker-Day {
    border-radius: 0 !important;
  }
`}</style>
        </Helmet>
      </div>
    );
  }
}

// const mapStateToProps = state=>{
//   return {
//     stateFromStore : state.data
//   }
// }
const mapDispatchToProps = dispatch => ({
  dispatchFromStore: (from, to) => {
    dispatch(action.getStudyByDate(from, to));
  },
  getStudyFromStore: (from, to) => {
    dispatch(action.getStudyByDate(from, to));
  }
});

export default connect(null, mapDispatchToProps)(leftside);
