import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import clsx from 'clsx';


const useStyles = makeStyles({
    root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
          outline: '2px auto rgba(19,124,189,.6)',
          outlineOffset: 2,
        },
        'input:hover ~ &': {
          backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
          boxShadow: 'none',
          background: 'rgba(206,217,224,.5)',
        },
      },
      checkedIcon: {
        backgroundColor: '#137cbd',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
          display: 'block',
          width: 16,
          height: 16,
          backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
          content: '""',
        },
        'input:hover ~ &': {
          backgroundColor: '#106ba3',
        },
      },
});

// Inspired by blueprintjs
function StyledRadio(props) {
    const classes = useStyles();
  
    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        {...props}
      />
    );
  }
  

class ButtonBases extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mod:null
        };

    }
    render() {
        const handleChange= event =>{
            var id=event.target.value;
            // console.log(id);
            this.setState({mod:id},()=>{
            this.props.handleMod(id);
            });
        }

        // const { classes } = this.props;
        return (
            <FormControl component="fieldset">
            <FormLabel component="legend"></FormLabel>
            <RadioGroup 
            onChange={handleChange}
             defaultValue={this.props.md} aria-label="xray" name="customized-radios">
            <FormControlLabel value="CR/DR" control={<StyledRadio />} label="CR/DR" />
              <FormControlLabel value="CT" control={<StyledRadio />} label="CT" />
              <FormControlLabel value="MRI" control={<StyledRadio />} label="MRI" />
              <FormControlLabel value="US" control={<StyledRadio />} label="US" />
              <FormControlLabel value="other" control={<StyledRadio />} label="Other" />
            </RadioGroup>
          </FormControl>
            // <div>
            //     {images.map(image => (
            //         <ButtonBase
            //             focusRipple
            //             key={image.title}
            //             className={classes.image}
            //             focusVisibleClassName={classes.focusVisible}
            //             style={{
            //                 width: image.width,
            //             }}
            //         >
            //             <span
            //                 className={classes.imageSrc}
            //                 style={{
            //                     backgroundImage: `url(${image.url})`,
            //                 }}
            //             />
            //             <span className={classes.imageBackdrop} />
            //             <span className={classes.imageButton}>
            //                 <Typography
            //                     component="span"
            //                     variant="subtitle1"
            //                     color="inherit"
            //                     className={classes.imageTitle}
            //                 >
            //                     {image.title}
            //                     <span className={classes.imageMarked} />
            //                 </Typography>
            //             </span>
            //         </ButtonBase>
            //     ))}
            // </div>
        );
    }
}
export default ButtonBases;