import React from "react";
// import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from "@material-ui/core/Typography";
// import Container from '@material-ui/core/Container';

import Mod from "./mod";
import HospitalList from "./hospitallist";
import PatientInfo from "./patientInfo";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import BackupTwoToneIcon from "@material-ui/icons/BackupTwoTone";
import axios from "axios";

import Upload from "./upload";

import Moment from "moment";

import * as member from '../Utils/Common';

const url = 'http://' + window.location.hostname + '/api';

const myId = member.hosId();
const hosName = member.hosName();

const genRefId = () => {
  var id = myId + "." + Moment(new Date()).format("YYYYMMDDhhmmss");
  console.log("ID:" + id);
  return id;
};

const useStyles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    minWidth: 300,
    width: "100%",
    padding: theme.spacing(2)
  },
  image: {
    position: "relative",
    height: 250,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15
      },
      "& $imageMarked": {
        opacity: 0
      },
      "& $imageTitle": {
        border: "4px solid currentColor"
      }
    }
  },
  focusVisible: {},
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%"
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity")
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) +
      6}px`
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity")
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  paper: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(3)
    }
  },
  toolbar: theme.mixins.toolbar,
  link: {
    display: "flex",
    color: "#fff"
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  }
});

class ButtonBases extends React.Component {
  initialState = {
    activeStep: 0,
    hospitalDest: '',
    hosId: myId,
    hosName: hosName,
    mod: "CR/DR",
    patient: {
      hn: null,
      cid: null,
      fname: null,
      lname: null,
      bdate: null,
      guid: genRefId(),
      refid: null,
      sex: "",
      stuDate: "",
      stuTime: "",
      doctor: "",
      stuDesc: ""
    },
    files: null
  };

  constructor(props) {
    member.onSession();
    // member.getRight("referTo", '/Study');
    super(props);
    this.state = { ...this.initialState };
  }
  render() {
    const { classes } = this.props;

    const Info = this.state;
    const { activeStep, hospitalDest, mod, patient } = this.state;

    const handleHosDest = hosid => {
      // console.log('From child' + hosid);
      this.setState({ hospitalDest: hosid });
    };

    const handleMod = md => {
      // console.log('From child' + md);
      this.setState({ mod: md });
    };

    const handlePatient = pt => {
      // console.log('return');
      // console.log(pt);
      this.setState({ patient: pt });
    };

    const handleFiles = file => {
      // console.log('return');
      // console.log(pt);
      this.setState({ files: file });
    };

    const postInfo = info => {
      axios.get(url + "/hospital/" + myId).then(res => { });
      axios.post(url + "/refer/uploadinfo/", info).then(res => {
        console.log(res.data);
      });
    };

    // Steper
    function getSteps() {
      return [
        "โรงพยาบาลปลายทาง",
        "ประเภทของภาพ (Modality)",
        "รายละเอียดผู้ป่วย",
        "เลือกไฟล์ DCM"
      ];
    }
    function getStepContent(step) {
      switch (step) {
        case 0:
          return (
            <HospitalList
              id={myId}
              dest={handleHosDest}
              destId={hospitalDest}
            />
          );
        case 1:
          return <Mod handleMod={handleMod} md={mod} />;
        case 2:
          return (
            <PatientInfo handlePatient={handlePatient} patient={patient} />
          );
        case 3:
          console.log(JSON.stringify({ Info }));
          return (
            <Upload guid={patient.guid} info={Info} handleFiles={handleFiles} />
          );
        case 4:
          return;
        default:
          console.log("last");
          return "Unknown step";
      }
    }

    const steps = getSteps();
    const handleNext = () => {
      // setActiveStep(prevActiveStep => prevActiveStep + 1);

      this.setState({ activeStep: activeStep + 1 });
      console.log(activeStep);
    };

    const handleBack = () => {
      this.setState({ activeStep: activeStep - 1 });
    };

    const handleReset = () => {
      console.log(activeStep);
      this.setState(this.initialState);
      this.setState({
        patient: {
          hn: null,
          cid: null,
          fname: null,
          lname: null,
          bdate: null,
          refid: null,
          guid: genRefId()
        }
      });
    };

    const MenuLink = () => {
      return (
        <Breadcrumbs aria-label="breadcrumb">
          <Link href="/Study" className={classes.link}>
            <HomeIcon className={classes.icon} />
            ย้อนกลับ
          </Link>ss
          <Typography color="textPrimary" className={classes.link}>
            <BackupTwoToneIcon className={classes.icon} />
            Upload referal
          </Typography>
        </Breadcrumbs>
      );
    };

    return (
      <React.Fragment>
        <CssBaseline />
        {/* <Appbar/>           */}
        <MenuLink />
        <Container fixed>
          <div className={classes.toolbar} />
          <Typography
            component="div"
            style={{ backgroundColor: "#fff", borderRadius: 40 }}
          >
            {/* <div className={classes.toolbar} />              */}
            <div className={classes.root}>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                      <Paper variant="outlined" className={classes.paper}>
                        <Typography>{getStepContent(index)}</Typography>
                      </Paper>
                      <br></br>
                      {/* <Divider /> */}
                      <div className={classes.actionsContainer}>
                        <div>
                          <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            variant="outlined"
                            className={classes.button}
                          >
                            ย้อนกลับ
                          </Button>
                          <Button
                            variant="contained"
                            color={
                              activeStep === steps.length - 1
                                ? "secondary"
                                : "primary"
                            }
                            onClick={handleNext}
                            className={classes.button}
                          >
                            {activeStep === steps.length - 1
                              ? "สิ้นสุด"
                              : "ถัดไป"}
                          </Button>
                        </div>
                      </div>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length && (
                <Paper square elevation={0} className={classes.resetContainer}>
                  {console.log("last step")}
                  {postInfo(Info)}
                  <Typography>
                    ขั้นตอนเรียบร้อย &apos;ข้อมูลถูกส่งสำเร็จ
                  </Typography>
                  <Button onClick={handleReset} className={classes.button}>
                    เริ่มใหม่
                  </Button>
                </Paper>
              )}
            </div>
          </Typography>
        </Container>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(ButtonBases);
