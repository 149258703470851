import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
// import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
// import List from "@material-ui/core/List";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import Leftside from "./leftside";
import Study from "./studylist";

// import { createStore, applyMiddleware } from 'redux';
// import reducer from '../reducer';
// import { Provider } from 'react-redux';
// import thunk from 'redux-thunk';
import Avatar from "@material-ui/core/Avatar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";

// import Zoom from '@material-ui/core/Zoom';
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import FormLabel from '@material-ui/core/FormLabel';
import MenuBookTwoToneIcon from '@material-ui/icons/MenuBookTwoTone';
import Badge from '@material-ui/core/Badge';

import * as member from '../Utils/Common';
import leftImage from "../images/bg_m.png";

import Notify from "./notify";

import Snackbar from '@material-ui/core/Snackbar';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
// import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import MuiAlert from '@material-ui/lab/Alert';
import { saveAs } from 'file-saver';

function Alert(props) {
  return <MuiAlert elevation={1} variant="filled" {...props} />;
}

// import Paper from '@material-ui/core/Paper';
// import HeaderBar from '../header/appbar';

// import uploadDcm from '../uploadDCM/index';
// let member = JSON.parse(localStorage.getItem('user'));

const drawerWidth = 320;
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
    // backgroundColor: '#95999f',
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      //   width: `calc(100% - ${drawerWidth}px)`,
      //   marginLeft: drawerWidth,
      zIndex: theme.zIndex.drawer + 1,
      // background: 'transparent', boxShadow: 'none'
    },
    backgroundColor: "#E6E6E6"
  },

  title: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  toolbar: theme.mixins.toolbar,

  drawerPaper: {
    width: drawerWidth,
    padding: theme.spacing(2),
    // backgroundColor: "#0A1B2A",
    backgroundImage: 'url(' + leftImage + ')',
    backgroundPosition: 'center',

  },
  content: {
    flexGrow: 1,
    justify: "center",
    padding: theme.spacing(2),
    // backgroundColor: '#e1eadc',
    width: `calc(100% - ${drawerWidth}px)`,
    // height: '100vh',
    zIndex: theme.zIndex.fab - 1
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zInde: theme.zIndex.appBar,
    // backgroundColor: '#FE642E'
  }
}));

function ResponsiveDrawer(props) {
  const { container } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const logo = require("../../components/images/gd4.png");
  const manual = require("../../components/images/manual.pdf");
  member.onSession();

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      {/* <Divider /> */}
      {/* <List> */}
      <Leftside />
      {/* </List> */}
      {/* <Divider /> */}
    </div>
  );
  const [alert, setAlert] = React.useState(false);
  const [ncase, setNcase] = React.useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/login';
  };


  // Add Icon
  const fabs = [
    {
      color: "secondary",
      className: classes.fab,
      icon: <AddIcon />,
      label: "Add"
    }
  ];

  const SnackhandleClose = () => {
    setAlert(false);
  }

  const handleNewStudy = (n, o) => {
    setNcase(n);
    if (n !== o & n > 0)
      setAlert(true);
  }


  let fab = fabs[0];


  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* <HeaderBar/> */}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar variant="dense">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon style={{ color: "#000" }} />
          </IconButton>
          {window.innerWidth > 700 ?
            <Avatar variant="rounded" style={{ backgroundColor: "#E6E6E6" }} className={classes.rounded}>
              <img
                src={logo}
                width="40"
                height="30"
                className="d-inline-block align-top"
                alt="GD4"
              />
            </Avatar>
            : ""}
          <div>
            {"\u00A0"}
            {"\u00A0"}
          </div>
          <Typography variant="h6" className={classes.title} noWrap>
            <span style={{ color: "#08088A" }}>UNEO</span><span style={{ color: "#FF0000" }}>X</span>
          </Typography>
          <div>
            <IconButton onClick={() => {
              saveAs(manual, "คู่มือการใช้งานส่ง Refer ภาพ.pdf");
            }
            }>
              <Badge color="secondary" >
                <MenuBookTwoToneIcon />
              </Badge>
            </IconButton>
            <Notify handleNewStudy={handleNewStudy} />


            <span style={{ color: "#0AB023" }}><AccountCircle /></span>  {"\u00A0"}
            <span>
              <FormLabel component="label" style={{ textAlign: "right", color: "#777" }}>{member.fullname()}</FormLabel>
              {window.innerWidth > 700 ? <FormLabel component="label" style={{ textAlign: "right", color: "#777" }}>[{member.hosName()}]</FormLabel> : ""}
            </span>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              // color="inherit"
              size="small"
            >
              <LockIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}><LockOpenIcon /> ออกจากระบบ</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Study />
      </main>
      <Fab
        variant="extended"
        aria-label={fab.label}
        className={fab.className}
        color={fab.color}
        disabled={member.usr().referTo === true ? false : true}
        href="/Add"
      >
        {fab.icon}
        Refer
      </Fab>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={alert}
        autoHideDuration={3000}
        onClose={SnackhandleClose}                >
        <Alert onClose={SnackhandleClose} severity="info">
          {ncase} รายการมาใหม่
    </Alert>
      </Snackbar>

    </div>

  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  container: PropTypes.instanceOf(
    typeof Element === "undefined" ? Object : Element
  )
};

export default ResponsiveDrawer;
