import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';

// import * as member from '../Utils/Common';

const url = 'http://' + window.location.hostname + '/api';

const useStyles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 300,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});


class mySelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            labelWidth: null,
            setLabelWidth: null,
            selectList: [],
            selectValue: ''
        };

    }
    componentDidMount() {
        axios.get(url + '/hospital/dest/' + this.props.id)
            .then(res => {
                let rt = res.data[0].referTo;
                if (rt.length === 0) {
                    window.location.href = '/Study';
                }
                const selectList = rt;
                // console.log(selectList);
                this.setState({ selectList });
            });

    }

    render() {

        // const [labelWidth, setLabelWidth] = React.useState(0);
        const { classes } = this.props;

        const handleChange = event => {
            var id = event.target.value;
            console.log('return ' + id);
            this.setState({ selectValue: id }, () => {
                this.props.dest(id);
            });
        }

        return (
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                    โรงพยาบาล
        </InputLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={this.props.destId}
                    onChange={handleChange}
                    labelWidth={100}
                >
                    <MenuItem value="">
                        <em>กรุณาเลือก</em>
                    </MenuItem>
                    {
                        this.state.selectList.map(
                            ls => <MenuItem value={ls.id}>{ls.name}</MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        );
    }
}
export default withStyles(useStyles)(mySelect);