import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';

import { createStore, applyMiddleware } from 'redux';
import reducer from './components/reducer';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import study from './components/study/index';
import uploadDcm from './components/uploadDCM/index';
import Login from './components/login/index';

import Regis from './components/login/register';

import './components/Utils/Common';



function App() {

  // const [authLoading, setAuthLoading] = useState(true);

  const store = createStore(reducer, applyMiddleware(thunk));
  // const classes = useStyles();
  // const logo = require('./components/images/gd4.png');



  // if (!getToken()) {
  //  // return <Redirect to='/login'  />
  //  this.props.history.push('/login/');
  // }
  return (
    <Provider store={store}>
      <div>
        <BrowserRouter>
          <Switch>
            <Route exact path="/Study" component={study} />
            <Route exact path="/Add" component={uploadDcm} />
            <Route exact path="/" component={Login} />
            <Route exact path="/Login" component={Login} />
            <Route exact path="/Register" component={Regis} />
            {/* <Route exact path="/test" component={layout}/> */}
          </Switch>
        </BrowserRouter>
      </div>
    </Provider>
  );

}

export default App;
