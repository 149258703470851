const initialState = {
    study: []
}

const reducer = (state = initialState, action) => {
    // console.log(state);

    const allStudy = [...state.study];
    switch (action.type) {
        case 'GET_DATA':
            const loadOne = {
                ...state,
                study: action.playload
            }
            //  console.log('load one' + JSON.stringify(loadOne));
            return loadOne;
        case 'UPDATE_DATA':
            const id = allStudy.findIndex((dt) => {
                return dt.guid === action.playload.guid;
            })
            allStudy[id] = {
                Accept: action.playload.Accept,
                AcceptBy: action.playload.AcceptBy
            }
            const update = {
                ...state,
                study: allStudy
            }
            return update;
        case 'REFRESH_DATA':
            const i = allStudy.findIndex((dt) => {
                return dt.guid === action.playload.guid;
            })
            allStudy[i] = action.playload;
            const refresh = {
                ...state,
                study: allStudy
            }
            return refresh;
        case 'GETALL':
            const loadState = {
                ...state,
                study: action.playload
            }
            return loadState;
        default:
            break;
    }
    return state;
}

export default reducer;