import React, { Component } from "react";
// import ReactDOM from 'react-dom'
import MaterialTable from "material-table";

import axios from 'axios';
import Moment from "moment";
import { connect } from "react-redux";

import * as action from "./action";

import Paper from "@material-ui/core/Paper";

import Viewer from "../viewer";
// import {Modal,Button} from 'react-bootstrap';

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import SaveIcon from "@material-ui/icons/Save";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedInTwoTone';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import NewReleasesIcon from '@material-ui/icons/NewReleasesTwoTone';
import SendIcon from '@material-ui/icons/Send';


import Badge from 'react-bootstrap/Badge';

// import SaveAltIcon from "@material-ui/icons/SaveAlt";

import PageviewIcon from "@material-ui/icons/Pageview";

// import MmsIcon from "@material-ui/icons/Mms";

import { Card } from "react-bootstrap";

import { withStyles } from "@material-ui/core/styles";

import Fab from "@material-ui/core/Fab";
// import AddIcon from "@material-ui/icons/Add";
import ReplyIcon from '@material-ui/icons/Reply';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Tooltip from '@material-ui/core/Tooltip';

import * as dcm from "./download";

import Upload from "../uploadDCM/upload";

import * as member from '../Utils/Common';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import { json } from "body-parser";
// import leftImage from "../images/bg_m.png";
const url = 'http://' + window.location.hostname +'/api';

const useStyles = theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular,
  },
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  detail: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(3)
  }
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class StudyLists extends Component {
  state = {
    dialogBack: false,
    modalShow: false,
    setModalShow: false,
    fileSystem: null,
    filePath: null,
    files: null,
    filesBack: null,
    guid: null,
    datas: [{}]
  };

  componentDidMount() {
    console.log("study");
    //this.setState({datas:this.props.studyFromStore});
    //console.log(this.state.datas);
    // this.props.getAll();
    //this.setState({items:this.props.studyFromStore});
  }

  render() {
    const {
      dialogBack,
      modalShow,
      selectHN,
      ptName,
      files,
      // filesBack,
      fileSystem,
      filePath,
      guid
    } = this.state;

    const { classes } = this.props;
    //  const [open] = this.setState;

    const handleClickOpen = (id, name, file, guid, fs, path) => {
      this.setState({
        ...this.state,
        modalShow: true,
        selectHN: id,
        ptName: name,
        files: file,
        guid: guid,
        filePath: path,
        fileSystem: fs,
        // filesBack: ''
      });

    };

    const handleClose = () => {
      this.setState({ modalShow: false });
    };

    const items = this.props.studyFromStore;
    // console.log(items);
    // this.setState({datas: items});
    //console.log(this.state.items);
    // this.setState({ row: items });

    var hosname = {};
    items.map(st => (hosname[st.hosId] = st.hosName));
    // items.map(h => (
    //   // h['filesBack'] = []
    //   ReferBack(h)
    //   ))

    const viewAll = rowData => {
      return (
        <div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<PageviewIcon style={{ color: '#FCED04' }} />}
            onClick={event =>
              handleClickOpen(
                "HN: " + rowData.hn + " " + rowData.name,
                "",
                rowData.files,
                rowData.guid,
                rowData.fileSystem,
                rowData.filePath
              )
            }
          >
            ดูภาพ
        </Button>
          <Typography variant="caption" paragraph="true" color="error">
            *ดูภาพทั้งหมด ภาพต้องมาจาก Study เดียวกัน เช่น US,CT,MRI
        </Typography >
        </div>
      );
    };

    const buttonSave = (rowData, file) => {
      return (
        <div>
          <Button
            variant="contained"
            style={{ backgroundColor: "#ff8533" }}
            size="small"
            startIcon={<SaveIcon />}
            // disabled
            onClick={event => dcm.downloadAll(rowData.guid, file)}
          >
            Zip
        </Button>
          <Typography variant="caption" paragraph="true" color="error">
            *ดาวโหลดทั้งหมดเป็นไฟล์ .zip
              </Typography >
        </div>
      );
    };

    // const buttonSaveDCM = (rowData, name) => {
    //   return (
    //     <Button
    //       variant="contained"
    //       color="primary"
    //       startIcon={<PageviewIcon />}
    //       onClick={event => dcm.downloadDCM(rowData, name)}
    //     >
    //       Download
    //     </Button>
    //   );
    // };
    const handleClickOpenBack = guid => {
      console.log("back: " + guid);
      this.setState({
        ...this.state,
        dialogBack: true,
        guid: guid
      });
    };
    const handleClickDelete = guid => {
      console.log("delete: " + guid);
      // this.setState({
      //   ...this.state,
      //   // dialogBack: true,
      //   guid: guid
      // });

      axios.post(url + '/study/delete/' + guid)
      .then(dt => {
        window.location.reload();
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
    };
    const handleCloseBack = () => {
      this.setState({ dialogBack: false });
    };

    // const handleFilesBack = file => {
    //   // console.log('return');
    //   // console.log(pt);
    //   this.setState({ filesBack: file });
    // };

    const downloadPanel = (title, rowData, files, color) => {
      return (
        <ExpansionPanel style={{ backgroundColor: color }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              {title} <span>
                {files != null && files.length > 0 ?
                  <Badge variant="light">{files.length}</Badge>
                  : ""}</span>
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Typography>
              <span>
                {files != null && files.length > 0
                  ?
                  viewAll(rowData)
                  : "ไม่พบไฟล์ภาพ/ไฟล์ภาพหมดอายุ"}
                {files != null && files.length > 0 ? buttonSave(rowData, files) : ""}

              </span>
              <p>
                <ButtonGroup
                  orientation="vertical"
                  color="primary"
                  aria-label="vertical contained primary button group"
                  variant="text"
                >
                  {files != null && files.length > 0
                    ? files.map((fi, index) => {
                      // console.log(fi);
                      return (
                        <div>
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "#f2f2f2" }}
                            size="small"
                            startIcon={<PhotoCameraIcon />}
                            onClick={event =>
                              handleClickOpen(
                                "HN: " +
                                rowData.hn +
                                " " +
                                rowData.name,
                                fi,
                                [fi],
                                rowData.guid,
                                rowData.fileSystem,
                                rowData.filePath
                              )
                            }
                          >
                            {fi}
                          </Button>
                          <IconButton
                            variant="contained"
                            color="primary"
                            onClick={event =>
                              dcm.downloadDCM(rowData, fi)
                            }
                          >
                            <SaveIcon />
                          </IconButton>
                        </div>
                      );
                    })
                    : ""}
                </ButtonGroup>
              </p>
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )
    }

    const ReferBack = (rowData) => {
      console.log('back');
      // rowData["filesBack"] = [];

      let fi = [];
      axios.get(url + '/refer/back/' + rowData.guid)
        .then(dt => {
          fi = dt.data;
          // rowData["filesBack"] = fi;
          // console.log(rowData);
          this.setState({ [`${rowData.guid}`]: fi });
          // this.setState({ "filesBack": fi });
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
      // console.log(this.state[`${rowData.guid}`]);
      // console.log(rowData);
      // console.log(rowData.filesBack);
      return this.state[`${rowData.guid}`];
    }


    const detailPanel = rowData => {

      return (
        <div>
          <Paper className={classes.detail} elevation={3}>
            <Card>
              <Card.Header>{detailView(rowData)}</Card.Header>
              <Card.Body>

                {/* <Divider /> */}
                <br />
                {/* <p>{buttonSave(rowData)}</p> */}
                {downloadPanel('รายการภาพที่ส่ง Refer ', rowData, rowData.files, "#c6d2da")}

                {downloadPanel('รายการภาพส่งกลับ', rowData, this.state[`${rowData.guid}`], "#b8d94a")}

                <br />

              </Card.Body>
              <Card.Footer>
                {
                  rowData.DownloadBy.length > 0 ?
                    downloadLog(rowData)
                    : ""}
              </Card.Footer>
            </Card>
          </Paper>
        </div >
      );
    }
    const downloadLog = rowData => {
      let d = rowData.DownloadBy;
      return (
        <div>
          <Typography component="span"
            variant="subtitle2" style={{ color: "#665200" }}>ประวัติการบันทึกไฟล์</Typography>
          {
            d.map(log => {
              return (
                <div>
                  <Typography component="span"
                    variant="caption"
                    style={{ color: "#665200" }}>{log}</Typography>
                </div>
              )
            })
          }
        </div>
      )
    }
    const detailView = rowData => {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}>
            <TextField
              id="standard-read-only-input"
              label="Refer Id"
              defaultValue={rowData.refId}
              size="small"
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="standard-read-only-input"
              label="Sex"
              size="small"
              defaultValue={rowData.sex}
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="standard-read-only-input"
              label="BirthDate"
              size="small"
              defaultValue={Moment(rowData.birthDate).format("DD/MM/YYYY")}
              InputProps={{
                readOnly: true
              }}
            />{" "}
          </Grid>
          {/* <Grid item xs={12} sm={2}>
            <TextField
              id="standard-read-only-input"
              label="จำนวนภาพ"
              size="small"
              defaultValue={rowData.files != null ? rowData.files.length : 0}
              InputProps={{
                readOnly: true
              }}
            />
          </Grid> */}
          <Grid item xs={12} sm={3}>
            {rowData.Accept == null | rowData.Accept !== true ?
              <Fab
                variant="extended"
                size="small"
                color="secondary"
                aria-label="accept"
                className={classes.margin}
                disabled={member.hosId() === rowData.toHos ? false : true}
                //  href={'/Back/' + rowData.guid}
                onClick={event => {
                  console.log('accept');
                  //console.log(this.props.studyFromStore);
                  axios.post(url + '/study/accept/' + rowData.guid + "/" + member.fullname())
                    .then(dt => {
                      this.props.getRefreshData(rowData.guid);
                      // refreshStudyByGuid(rowData.guid);
                    })
                    .catch(function (error) {
                      // handle error
                      console.log(error);
                    });


                }}
              >
                <CheckCircleIcon />
                Accept
            </Fab>
              :
              <Chip label={rowData.AcceptBy} style={{ color: "#17951F", fontWeight: "bold", fontSize: 12 }} icon={<DoneIcon style={{ color: "#17951F" }} />} />
            }
          </Grid>
          <Grid item xs={12} sm={3}>
            <Fab
              variant="extended"
              size="small"
              color="primary"
              aria-label="add"
              className={classes.margin}
              disabled={member.hosId() === rowData.toHos ? false : true}
              //  href={'/Back/' + rowData.guid}
              onClick={event => {
                handleClickOpenBack(rowData.guid);
              }}
            >
              <ReplyIcon />
              ส่งกลับ
            </Fab>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Fab
              variant="extended"
              size="small"
              color="secondary"
              aria-label="del"
              className={classes.margin}
              disabled={member.hosId() === rowData.hosId ? false : true}
              //  href={'/Back/' + rowData.guid}
              onClick={event => {
                handleClickDelete(rowData.guid);
              }}
            >
              <DeleteForeverIcon />
              ลบรายการ
            </Fab>
          </Grid>
        </Grid>
      );
    };
    // hosname.forEach(function(value,i){
    //   console.log(value);
    // });
    // console.log(hosname);
    // console.log('from datatable ' + JSON.stringify(items));
    return (
      <div>
        <MaterialTable
          style={{
            backgroundColor: "#FAFAFA",
            padding: 10,
            borderRadius: 10,
            color: "#464646"
          }}

          title={"" + items.length + ""}
          columns={[
            // {
            //   title: 'Ref.Id', field: 'refId'
            //   ,
            //   cellStyle: {
            //     backgroundColor: '#c76f46',
            //     color: '#FFF'
            //   },
            //   headerStyle: {
            //     // backgroundColor: '#c76f46',
            //   },
            //   width: 100
            // },
            {
              title: "",
              width: 20,
              render: d => (<div>{d.Accept === true ?
                <Tooltip title={d.AcceptBy}>
                  <AssignmentTurnedInIcon style={{ color: "#33cc33" }} />
                </Tooltip>
                : d.hosId === member.hosId() ? <SendIcon style={{ color: "#3399ff" }} /> : < NewReleasesIcon style={{ color: "#F87806" }} />}</div>),
              // headerStyle: {
              //   backgroundColor: '#ad491c',
              //   color: '#fff'
              // },
              cellStyle: {
                border: '2px solid #fff'
              }
            },
            {
              title: "HN",
              field: "hn",
              width: 100,
              cellStyle: {
                // backgroundColor: '#4998e1',
                // color: '#406c91',
                fontWeight: "bold",
                border: '2px solid #fff'
              }
              // headerStyle: {
              //   backgroundColor: '#ad491c',
              //   color: '#fff'
              // },
            },
            {
              title: "ชื่อ",
              field: "name",
              width: 0,
              cellStyle: {
                // backgroundColor: '#a8bdd5',
                fontWeight: "bold",
                border: '2px solid #fff'
              }
            },

            {
              title: "CID",
              field: "cid",
              width: 100,
              cellStyle: {
                // backgroundColor: '#a8bdd5',
                fontWeight: "bold",
                // color: '#5a3c23'
                border: '2px solid #fff'
              }
            },
            {
              title: "รพ.",
              field: "hosId",
              lookup: hosname,
              width: 150,
              cellStyle: {
                // backgroundColor: '#a8bdd5',
                fontWeight: "bold",
                border: '2px solid #fff'

                // color: '#976324'
              }
            },
            // {
            //   title: "referTo",
            //   field: "toHos",
            //   width: 50,
            //   cellStyle: {
            //     // backgroundColor: '#a8bdd5',
            //     fontWeight: "bold",
            //     border: '2px solid #fff'

            //     // color: '#976324'
            //   }
            // },
            // {
            //   title: 'MOD', field: 'modality',
            //   width: 100
            // },
            {
              title: 'จำนวน', field: 'NUM',
              render: d => (<div>{d.files != null ? d.files.length : 0}</div>),
              width: 20,
              cellStyle: {
                // backgroundColor: '#a8bdd5',
                fontWeight: "bold",
                border: '2px solid #fff'

                // color: '#976324'
              }
            },
            // {
            //   title: 'วันที่ทำ',
            //   field: 'stuDate',
            //   render: d => (<div>{Moment(d.stuDate).format("DD/MM/YYYY")}</div>),
            //   width: 100
            // }, {
            //   title: 'เวลา',
            //   field: 'stuTime',
            //   width: 100,
            //   render: d => (<div>{Moment(d.stuTime).format("HH:mm")}</div>),
            // },
            {
              title: "วันที่ส่ง",
              field: "refDate",
              render: d => <div>{Moment(d.refDate).format("DD/MM/YYYY")}</div>,
              width: 80,
              defaultSort: "desc",
              cellStyle: {
                fontWeight: "bold",

                border: '2px solid #fff'
                // backgroundColor: '#6db5b3',
                // color: '#877743'
              }
              // headerStyle: {
              //   backgroundColor: '#6db5b3',
              // },
            },
            {
              title: "เวลาที่ส่ง",
              field: "refTime",
              render: d => <div>{d.refTime}</div>,
              width: 80,
              defaultSort: "desc",
              cellStyle: {
                fontWeight: "bold",

                border: '2px solid #fff'
                // backgroundColor: '#6db5b3',
                // color: '#877743'
              }
              // headerStyle: {
              //   backgroundColor: '#6db5b3',
              // },
            }
          ]}
          data={items}
          // รายละเอียดภาพ
          detailPanel={(rowData) => detailPanel(rowData)}
          // actions={[
          //   {
          //     icon: 'photocamera',
          //     tooltip: 'ดูภาพ',
          //     onClick: (event, rowData) => (handleClickOpen(rowData.hn, 'All', rowData.files, rowData.guid, rowData.fileSystem, rowData.filePath))// alert("You saved " + rowData.name)
          //   }
          // ]}
          onRowClick={(evt, rowData, togglePanel) => {
            ReferBack(rowData)
            togglePanel();
          }
          }
          options={{
            // fixedColumns: {
            //   left: 3,
            //   right: 2
            //   // right: 1
            // },
            isLoading: true,
            headerStyle: {
              backgroundColor: "#FE642E",
              color: "#f0e8eb",
              height: "30px",
              fontSize: 14,
              fontWeight: "bold"
            },
            padding: "dense",
            grouping: true,
            // header: false,
            paging: false,
            // pageSize: 10,
            // pageSizeOptions: [10, 30, 50],
            filtering: true,
            toolbar: true,
            // search: false,
            // minBodyHeight: '100vh',
            //maxBodyHeight: "70vh",
            rowStyle: (x, id) => {
              if (id % 2) {
                return {
                  backgroundColor: "#E6E6E6",
                };
              } else {
                return {
                  backgroundColor: "#D8D8D8",
                };
              }
            }


          }}
        />

        {/* Viewer */}
        <Dialog
          fullScreen
          open={modalShow}
          onClose={handleClose}
          TransitionComponent={Transition}
          fullWidth="true"
          maxWidth="lg"
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                {selectHN}
                {/* {console.log(files)} */}
              </Typography>
              <Button autoFocus color="inherit" onClick={handleClose}>
                ปิด
              </Button>
            </Toolbar>
          </AppBar>
          <Paper>
            <Viewer
              dcm={files}
              name={ptName}
              guid={guid}
              fs={fileSystem}
              path={filePath}
            />
          </Paper>
        </Dialog>

        {/* Upload back */}
        <Dialog
          open={dialogBack}
          onClose={handleCloseBack}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"กรุณาเลือกไฟล์ DCM เพื่อส่งกลับ"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Upload guid={guid} handleFiles={(file) => (this.setState({ [guid]: file }))} />
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div >
    );
    // }
  }
}

const mapStateToProps = state => {
  return {
    studyFromStore: state.study
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAll: () => {
      return dispatch(action.getAllStudy());
    },
    getRefreshData: (guid) => {
      // console.log('dd ' + guid);
      return dispatch(action.refreshStudyByGuid(guid));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(useStyles)(StudyLists));
