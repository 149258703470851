import axios from "axios";
import Moment from "moment";
import * as member from '../Utils/Common';

const url = 'http://' + window.location.hostname + '/api';
export const getAllStudy = () => {
  return dispatch => {
    axios
      .get(url + "/study")
      .then(res => {
        dispatch({
          type: "GETALL",
          playload: res.data
        });
      })
      .catch(errors => {
        // react on errors.
        console.error(errors);
      });
  };
};
export const refreshStudyByGuid = (guid) => {
  return dispatch => {
    axios
      .get(url + "/study/guid/" + guid)
      .then(res => {
        console.log(res.data[0]);
        dispatch({
          type: "REFRESH_DATA",
          playload: res.data[0]
        });
      })
  }
}
export const getStudyByDate = (from, to) => {
  return dispatch => {
    if (!to && from) to = from;

    from = Moment(from).format("YYYYMMDD");
    to = Moment(to).format("YYYYMMDD");
    console.log("Moment : " + from + "-" + to);
    // console.log("ACTION : " + from + " " + to);
    axios
      .get(url + "/study/date/" + member.hosId() + "/" + from + "/" + to)
      .then(res => {
        dispatch({
          type: "GET_DATA",
          playload: res.data
        });
      })
      .catch(errors => {
        // react on errors.
        console.error("ERR:getStudyByDate " + errors);
      });
  };
};
