//ImageViewer.js
//Importing the React library
import React from 'react';
//Importing our component-folder that contains the three files
import DwvComponent from './DwvComponent';

// const { STORE, STORE_TMP } = require('./config');

class ImageViewer extends React.Component {

    render() {

        //const path = require('../IMG/STUDY/');
        const dcm = this.props.dcm;
        const file = [{}];
        console.log('path');
        console.log(window.location.origin);
        //console.log(this.props.location.pathname);
        //dcm.map((d, i) => file[i] = require(`/IMG/STUDY/${this.props.guid}/${d}`));
        //   dcm.map((d, i) => file[i] = require(`./../../public/IMG/STUDY/${this.props.guid}/${d}`));
        //  dcm.map((d, i) => file[i] = require(`/IMG/STUDY/${this.props.guid}/${d}`));
        console.log(dcm);

        function filePath(guid) {
            let hos = guid.substring(0, guid.indexOf('.'));
            let dt = guid.substring(guid.indexOf('.') + 1, guid.indexOf('.') + 9);
            return `${dt}/${hos}/${guid}`;
        }


        dcm.map((d, i) => file[i] = (`${window.location.origin}/IMG/STUDY/${filePath(this.props.guid)}/${d}`));
        console.log(file);
        // console.log(ff);
        // let fs = `/${this.props.fs}/${this.props.path}`;
        // dcm.map((d, i) => file[i] = require(`/IMG/STUDY/${this.props.guid}/${d}`));
        // const testdcm =require('../images/xray.dcm');
        //  `/${STORE}/${STORE_TMP}/${req.params.id}/`
        // console.log(file);
        //console.log('viewer');
        // console.log(JSON.stringify(file));
        return (
            <div style={{ height: '90vh' }}>
                <h4>{this.props.name}</h4>
                <DwvComponent
                    dcm={file}
                />
            </div>
        );
    }
}

export default ImageViewer;