import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
// import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles } from "@material-ui/core";

import Card from "@material-ui/core/Card";
import CardHeader from '@material-ui/core/CardHeader';
// import CardActionArea from "@material-ui/core/CardActionArea";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Button from "@material-ui/core/Button";
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Grid from '@material-ui/core/Grid';
import AccountCircle from '@material-ui/icons/AccountCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';

import Snackbar from '@material-ui/core/Snackbar';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import MuiAlert from '@material-ui/lab/Alert';
import axios from 'axios';
// import Info from './info';

const url = 'http://' + window.location.hostname + '/api';

function Alert(props) {
    return <MuiAlert elevation={1} variant="filled" {...props} />;
}

const useStyles = theme => ({
    root: {
        width: "100%",
    },
    form: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: 200,
        },
    },
    media: {
        height: 100,
        // paddingTop: "56.25%" // 16:9
    },
    toolbar: theme.mixins.toolbar,
    input: {
        width: "220px",
        fontSize: '17px',
        color: '#003366',
        fontWeight: 'bold',
    },
    inputName: {
        width: "220px",
        fontSize: '17px',
        color: '#003366',
        backgroundColor: '#FAFEB8',
        fontWeight: 'bold',
    }
});
class regis extends React.Component {
    state = {
        fullname: '',
        username: '',
        password: '',
        password2: '',
        hosId: '',
        showPassword: false,
        showPassword2: false,
        alert: false,
        alertMsg: '',
        showInfo: false,
    };
    render() {
        const { classes } = this.props;

        // const [values, setValues] = React.useState({
        //   username: '',
        //   password: '',
        //   showPassword: false,
        // });
        const handleChange = prop => event => {
            this.setState({ ...this.state, [prop]: event.target.value });
        };

        const handleClickShowPassword = () => {
            this.setState({ ...this.state, showPassword: !this.state.showPassword });
        };

        const handleMouseDownPassword = event => {
            event.preventDefault();
        };

        const handleClickShowPassword2 = () => {
            this.setState({ ...this.state, showPassword2: !this.state.showPassword2 });
        };

        const handleClose = () => {
            this.setState({ ...this.state, alert: false });
        }

        const msg = (info, st) => {
            this.setState({ ...this.state, alert: st, alertMsg: info });
        }
        const onRegis = () => {
            if (this.state.fullname.length === 0 |
                this.state.username.length === 0 |
                this.state.password.length === 0 |
                this.state.password === 0 |
                this.state.password2 === 0) {
                //this.setState({ ...this.state, alert: true });
                msg('กรุณากรอกข้อมูลให้ถูกต้อง', true);
                return;
            }
            if (this.state.username.length > 0 &&
                this.state.password.length > 0 && this.state.password === this.state.password2) {
                // console.log(this.state);
                axios.post(url + "/login/add/", this.state).then(res => {
                    console.log(res.data.user);
                    if (res.data.user === '1')
                        window.location.href = '/Login';
                    else if (res.data.user === '-1') {
                        msg('รหัสผู้ใช้ซ้ำกรุณาลองใหม่อีกครั้ง', true);
                    }
                });
            } else {
                this.setState({ username: '', password: '' });
                if (this.state.password !== this.state.password2) {
                    msg('รหัสผ่านไม่ตรงกัน', true);
                    return;
                }
            }
        }
        const onCancel = () => {
            window.location.href = '/Login';
        }

        return (
            <div>
                <React.Fragment>
                    <CssBaseline />
                    <Container fixed maxWidth="md">
                        <Typography component="div" style={{ height: '100vh' }} >
                            <div className={classes.toolbar} />
                            <Card className={classes.root} style={{ backgroundColor: "#fff", borderRadius: 20 }}>
                                <CardHeader
                                    avatar={
                                        <img
                                            src={require("../../components/images/gd4.png")}
                                            width="40"
                                            height="30"
                                            className="d-inline-block align-top"
                                            alt="UNEOX"
                                        />
                                    }
                                    title={<Typography variant="h6" className={classes.title} noWrap>
                                        <span style={{ color: "#0000bb" }}>UNEO</span><span style={{ color: "#ff0000" }}>X</span>
                                    </Typography>}
                                    subheader="Radiology Referral"
                                />
                                <Typography variant="body2" component="p" style={{ backgroundColor: "#ced3d7" }}>
                                    *หลังจากการลงทะเบียน กรุณาติดต่อเจ้าหน้าที่ของบริษัทเพื่อให้สิทธิในการเข้าใช้งาน
                                 </Typography>
                                <Typography variant="body2" component="p" style={{ backgroundColor: "#ced3d7" }}>
                                    **รหัสโรงพยาบาลหากไม่ทราบให้ปล่อยว่างไว้
                                 </Typography>
                                {/* <CardMedia
                                    className={classes.media}
                                    image={require('../images/head.png')}
                                    title="UNEO Refer"
                                /> */}
                                <CardContent style={{ backgroundColor: "#44727b" }}>

                                    <Grid container className={classes.root} spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container justify="center" spacing={1}>
                                                <form className={classes.form} noValidate autoComplete="off">
                                                    <h5 style={{ color: "#fff" }}>ข้อมูลผู้ใช้งาน</h5>
                                                    <Paper component="form" className={classes.inputName} >
                                                        <InputBase
                                                            className={classes.input}
                                                            placeholder="ชื่อ-นามสกุล"
                                                            value={this.state.fullname}
                                                            onChange={handleChange('fullname')}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <PermContactCalendarIcon color="primary" />
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </Paper>
                                                    <br></br>
                                                    <Paper component="form" className={classes.input} >

                                                        <InputBase
                                                            className={classes.input}
                                                            placeholder="รหัสผู้ใช้/username"
                                                            value={this.state.username}
                                                            onChange={handleChange('username')}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <AccountCircle color="primary" />
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </Paper>
                                                    <br></br>
                                                    <Paper component="form" className={classes.input} >
                                                        <InputBase
                                                            className={classes.input}
                                                            placeholder="รหัสผ่าน/password"
                                                            type={this.state.showPassword ? 'text' : 'password'}
                                                            value={this.state.password}
                                                            onChange={handleChange('password')}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        onMouseDown={handleMouseDownPassword}
                                                                        edge="end"
                                                                    >
                                                                        {this.state.showPassword ? <Visibility color="secondary" /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </Paper>
                                                    <br></br>
                                                    <Paper component="form" className={classes.input} >
                                                        <InputBase
                                                            className={classes.input}
                                                            placeholder="รหัสผ่านอีกครั้ง"
                                                            type={this.state.showPassword2 ? 'text' : 'password'}
                                                            value={this.state.password2}
                                                            onChange={handleChange('password2')}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword2}
                                                                        onMouseDown={handleMouseDownPassword}
                                                                        edge="end"
                                                                    >
                                                                        {this.state.showPassword2 ? <Visibility color="secondary" /> : <VisibilityOff />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </Paper>
                                                    <br></br>
                                                    <Paper component="form" className={classes.input} >
                                                        <InputBase
                                                            className={classes.input}
                                                            placeholder="**รหัสโรงพยาบาล"
                                                            value={this.state.hosId}
                                                            onChange={handleChange('hosId')}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <LocalHospitalIcon color="primary" />
                                                                </InputAdornment>
                                                            }
                                                        />
                                                    </Paper>
                                                    <br></br>
                                                    <Button variant="contained" color="primary" onClick={onRegis}>
                                                        ลงทะเบียน
                        </Button>
                                                    {"\u00A0"}
                                                    {"\u00A0"}
                                                    {"\u00A0"}
                                                    <Button variant="contained" onClick={onCancel} >
                                                        ยกเลิก
                        </Button>
                                                </form>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                            <p style={{ color: "#fff" }}>version 1.0 last update 02/2020</p>
                        </Typography>
                    </Container>
                </React.Fragment>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={this.state.alert}
                    autoHideDuration={3000}
                    onClose={handleClose}                >
                    <Alert onClose={handleClose} severity="error">
                        {this.state.alertMsg}
                    </Alert>
                </Snackbar>

            </div>
        );
    }
}

export default withStyles(useStyles)(regis);
